import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Synergo Pool</h1>
        <h5>
          Synergo Pool is a delegated staking pool on the Verus platform, leveraging the VerusID primitives it offers.
        </h5>
      </header>
      <div className='content'>
        <div className='bulletpoints'>
          <ul>
            <li>First ever delegated staking pool on the Verus platform</li>
            <li>Always 100% in control of your funds</li>
            <li>No staking hardware required</li>
          </ul></div>
        <br></br>
        <hr></hr>
        <br></br>
        <p className='ann'>
          Ever since the creation of Bitcoin and its Proof-of-Work mining protocol, small miners have been pooling their computational power in order to lower the variance in mining income. The idea is simple: more miners = more blocks. If you bundle a couple of small miners together and divide the mined income proportional to how much work every miner has put in to mine that block, you get a steadier stream of income.
        </p>
        <p className='ann'>
          Verus uses a hybrid mining algorithm: 50% Proof-of-Work (PoW) and 50% Proof-of-Stake (PoS). PoS is a mechanism where the size of your VRSC stake proportional to all the staking VRSC in the network determines your chance of generating the next block. In Verus' case this means: more VRSC = more blocks.
        </p>
        <p className='ann'>
          To pool your VRSC similar to how computational power is pooled with PoW is hard: The essence of Proof-of-Stake is that the funds of which you hold the private key <i>are the miners</i>. You can't point those funds somewhere to stake together, because in order to stake, a native wallet (with the private key that holds your funds) needs to participate in the network to get a chance of staking a block. If you still want to stake in a pool, you'd have to place a lot of trust in a custodial staking pool, by sending them your funds and trust that they will send back your funds when you want to.
        </p>
        <p className='ann'>
          Currently, there is 1 such staking pool for Verus, called <a href="https://docs.google.com/spreadsheets/d/1Up1WbMuCR21e6TxLae6zjLJePu_RIOVZwqShRj9vVvc">Dudezmobi staking</a>. It is run by a Verus community member and requires users that want to pool their stake to send over their funds to this community member. In other words, a custodial staking pool.        </p>
        <p className='ann'>
          Synergo pool is a new staking pool for the Verus Platform that solves the problem of having to give away control of your funds in order to stake in a pool.<br></br><br></br>
          Synergo pool uses the following VerusID primitives to make this possible:
        </p>
        <p className='ann'>
          <strong>VerusID</strong>
        </p>
        <p className='ann'>
          One of the VerusID properties is multisig: adding an additional primary address to your VerusID effectively makes it a 1-2 multisig. Adding an additional primary address allows for sharing the funds that are put in the identity. The owners of both primary addresses can now sign transactions with the VerusID and thus both can now enable staking with the funds in the VerusID. By adding a Synergo pool address to your VerusID, you allow the pool to stake with your funds.        </p>
        <p className='ann'>
          <strong>Verus Vault</strong>
        </p>
        <p className='ann'>
          <a href="https://docs.verus.io/verusid/#verus-vault">Verus Vault</a> allows VerusIDs to be locked. In order to send away any funds, the locked VerusID must be unlocked first, except for when a stake is found. A locked VerusID can stake while being locked. This is useful for staking in a pool: stake with many people together, while assuring that nobody can run off with your funds, because you locked the funds in the identity.   </p>
        <p className='ann'>
          <strong>Revoke and Recover</strong>
        </p>
        <p className='ann'>
          The Staking pool uses several precautions to ensure no private keys are leaked. In the unlikely event this happens, your identity could be updated by a hacker without you knowing about it (your identity is either being unlocked or the primary address is changed). But, because the VerusID is locked, the attacker needs to wait until the VerusID unlocks in order to spend funds. Meanwhile, you get notified of this update in your VerusID through a DM on Discord (A Telegram Notifier integration is in the pipeline). You then have the time (that you set when you locked the VerusID) to use your Revoke Identity to revoke all access to the staking identity, and then use your Recover identity to update your identity to stay in control of your identity.
          Using these <a href="https://docs.verus.io/verusid/#revoke-recover">Recover and Revoke capabilities</a>  of VerusIDs, this also means that Synergo Pool can never run off with your funds without you knowing about it beforehand.
          <br></br>
          <br></br>
          <a href="https://docs.verus.io/verusid/">Read more about VerusID</a> and join the <a href="https://verus.io/discord">Verus Discord</a> to learn more.
        </p>
        <hr></hr>
        <p className='ann'>
          <strong>How it (basically) works</strong>
        </p>
        <p className='ann'>
          Using the <a href="https://discord.gg/JFxcJzarrd">Synergo Pool Discord</a>, you subscribe to the pool by submitting the VerusID you want to stake with. The pool checks if the VerusID meets the requirements (see below), and sends a DM with a prepared `updateidentity` command to update the VerusID. <br></br>It takes your VerusID as is, and adds <br></br>(1) a primary address that is owned by Synergo Pool and <br></br>(2) sets a delay lock with a default of 24 hours.
        </p>
        <p className='ann'>After you successfully updated the VerusID (using the CLI or Verus-Desktop), the pool will pick up this change and send you a DM once all the checks pass. From here on, you are staking in the pool.</p>
        <p className='ann'>
          In order to join the staking pool, the VerusID should have:

          <ul><li>exactly 1 minimumsignatures</li>
            <li>at least 2 primary addresses, one of which is of the pool</li>
            <li>a Delay Lock of at least 12 hours</li>
            <li>a Revoke and Recover authority different from the VerusID address itself. This is needed in order to always be in control of your funds, even when your VerusID is compromised.
            </li>
          </ul>
          <br></br>
          If the VerusID is updated but the requirements are not met, the VerusID will be ignored by the pool and will not earn staking rewards.
        </p>
        <p className='ann'>
          Just like with a PoW mining pool, shares are used to express the work you contributed to staking the next block. Where PoW expresses the hashrate of your miner(s) as shares to keep track, Synergo Pool uses your staking balance as your share. Every new block that is not mined by the pool simply increases your shares with the eligible balance that was staking at that point.
          When a block is staked by a member of Synergo Pool, all the shares from everyone up until staking that block are used to divide the block reward among the pool participants, similar to dividing a block reward in a PoW mining pool.
        </p>
        <p className='ann'>
          <strong>How to get started</strong>
        </p>
        <div className='ann'>
          <ol className='ann'>
            <li>Join the <a href="https://discord.gg/JFxcJzarrd">Synergo Pool Discord</a></li>
            <li>Sign up using the <code>`/subscribe`</code> command (coming soon)</li>
            <li>Update your VerusID using the Verus Desktop wallet (or the CLI) with the prepared update command sent to you in DM</li>
            <li>Wait for a confirmation DM in Discord</li>
            <li>Done!</li>
          </ol>
        </div>
        <div className='faq'>
          <strong>F.A.Q.</strong>
          <ul>
            <li>Will the pool be open source?<br></br> {'-->'} Eventually, yes. Right before the announcement, I had plans to make it closed source, but after talking to some people I decided it would be best to make it open source. Soon, anyone can run a staking pool!</li>
            <li>Will the pool support PBaaS chains?<br></br> {'-->'} Right from the start, the pool supports staking PBaaS chains. You can even use your exported VRSC-identity to stake on different chains!</li>
            <li>When will the pool go live?<br></br>{'-->'} Soon. At this point, a working proof-of-concept has been developed. It is being tested on VRSCTEST. If you want to be an early-stage tester, feel free to drop me a DM.</li>
            <li>Who is this I person?<br></br>-{'>'} On Discord: jorian#4555 (242635006516658197).</li>
          </ul>
        </div>
      </div>
    </div >
  );
}

export default App;
